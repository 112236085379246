import { useCredentials } from '@data-c/hooks'
import { Box, Button } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import Unauthorized from 'components/Displays/Unauthorized'
import credentials from 'configs/credentials'
import { ReactNode, useCallback, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Header } from 'components/MioCandidate/Header'
import { Menu } from 'components/MioCandidate/Menu'
import { MenuItemProps } from 'components/MioCandidate/Menu/MenuItem'
import Versao from 'components/MioCandidate/Versao'
import { useMenu } from 'components/Contexts/MenuContext'
import Profile from './Profile'
import * as Sentry from '@sentry/react'
import Fallback from 'components/Fallback'
export const drawerWidth = 256

interface LayoutProps {
  children: ReactNode
  role?: string
}

function renderMenuItems(items: MenuItemProps[]): React.ReactNode {
  return items.map((item) => {
    // Verifica se o item deve ser renderizado (com base na propriedade `visible`)
    if (!item.visible) {
      return null
    }

    return (
      <Menu.Item
        key={item.id}
        link={item.link}
        id={item.id}
        label={item.label}
        icon={item.icon}
      >
        {/* Renderiza os subitens de forma recursiva, se existirem */}
        {item.items && renderMenuItems(item.items)}
      </Menu.Item>
    )
  })
}

export default function Layout(props: LayoutProps) {
  const { children, role } = props
  const { isAuthenticated } = useCredentials(credentials)
  const { temPermissao, loadUser, isLoading } = useUserContext()
  const { toggleMenu, isOpen } = useMenu()

  useEffect(() => {
    loadUser()
  }, [])

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  let podeAcessar = true

  if (role && !temPermissao(role)) {
    podeAcessar = false
  }

  const menuItems = [
    {
      id: 'menu_aplicativos',
      label: 'Aplicativos',
      icon: 'devices',
      link: '/aplicativos',
      visible: temPermissao('aplicativos.visibilidade'),
    },
    {
      id: 'menu_empresas',
      label: 'Empresas',
      icon: 'store',
      link: '/empresas',
      visible: temPermissao('empresas.visibilidade'),
    },
    {
      id: 'menu_usuarios',
      label: 'Usuários',
      icon: 'group',
      link: '/usuarios',
      visible: temPermissao('usuarios.visibilidade'),
    },
    {
      id: 'menu_contratos',
      label: 'Contratos',
      icon: 'handshake',
      link: '/contratos',
      visible: temPermissao('contratos.visibilidade'),
    },
  ]

  const memoizedRenderMenuItems = useCallback(
    () => renderMenuItems(menuItems),
    [menuItems],
  )

  return (
    <Box
      sx={{
        // backgroundColor: '#fff',
        // backgroundImage: `url('${bgImage}')`,
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        height: (theme) => `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        display: 'flex',
        marginTop: (theme) => `${theme.mixins.toolbar.height}px`,
      }}
    >
      <Header.Root>
        <Header.Content>
          <Header.ToggleMenu onClick={toggleMenu} />
          <Button
            sx={{ fontSize: '0.950rem', pt: 0, pb: 0, pl: 0.5, pr: 0.5 }}
            variant="text"
          >
            Central de Contas
          </Button>
          <Versao sectionId="33453161556884" />
        </Header.Content>
        <Header.Content>
          <Profile />
        </Header.Content>
      </Header.Root>
      <Menu.Root open={isOpen} toggleMenu={toggleMenu}>
        {memoizedRenderMenuItems()}
      </Menu.Root>
      {/* <Menu menus={menuItens} /> */}

      <Box
        sx={{
          // background: '#444',
          // pt: 0.5,
          display: 'flex',
          flexDirection: 'row',
          flex: '1 1 0%',
          position: 'relative',
          overflow: 'auto',
        }}
      >
        <Sentry.ErrorBoundary
          fallback={(errorProps) => <Fallback {...errorProps} />}
        >
          {podeAcessar && children}
          {!podeAcessar && !isLoading && <Unauthorized />}
        </Sentry.ErrorBoundary>
      </Box>
    </Box>
  )
}
