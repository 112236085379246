import { CircularProgress, Stack, Typography } from '@mui/material'
import { ContratoModel } from 'hooks/queries/useContratos'
import { lazy, Suspense } from 'react'

const componentMap: Record<
  string,
  React.LazyExoticComponent<
    React.ComponentType<{
      contrato: ContratoModel
    }>
  >
> = {
  MioMailer2: lazy(() => import('./MioMailer2')),
}

export default function DynamicComponent({
  dataCConfigPage,
  contrato,
}: {
  dataCConfigPage: string
  contrato: ContratoModel
}) {
  const LazyComponent = componentMap[dataCConfigPage]

  if (!LazyComponent) {
    return (
      <Stack direction="row" spacing={1}>
        <CircularProgress size={16} /> <Typography>Carregando...</Typography>
      </Stack>
    )
  }

  return (
    <Suspense
      fallback={
        <Stack direction="row" spacing={1}>
          <CircularProgress size={16} /> <Typography>Carregando...</Typography>
        </Stack>
      }
    >
      <LazyComponent contrato={contrato} />
    </Suspense>
  )
}
