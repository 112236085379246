import { Alert, Container, Stack, Tab, Tabs } from '@mui/material'
import TabPanel from 'components/TabPanel'
import useContratos, { ContratoModel } from 'hooks/queries/useContratos'
import useTabs from 'hooks/useTabs'
import TabEditor from './components/TabEditor'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Content from 'components/Layouts/Content'
import Loading from 'components/Displays/Loading'
import { useParams } from 'react-router-dom'
import TabToken from './components/TabToken'
import DynamicComponent from './components/DynamicComponent'

export default function Contrato() {
  const { id } = useParams()
  const { useQueryByUuid } = useContratos()
  const { data, isLoading, error } = useQueryByUuid<ContratoModel>(id as string)
  const { handleChange, value } = useTabs()

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <ContentTitle.Root>
        <Breadcrumbs
          links={[
            {
              label: 'Contratos',
              link: '/contratos',
            },
            {
              label: `${data?.aplicativo?.nome} | ${
                data?.usuario?.name || ''
              } | ${data?.empresa?.nome}`,
              link: `/contratos/${id}`,
            },
          ]}
        />
        <Stack direction="row">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs produtos"
          >
            <Tab label="Plano" value={0} />
          </Tabs>
          <Tabs value={value} onChange={handleChange} aria-label="tabs tokens">
            <Tab label="Tokens" value={1} />
          </Tabs>
          {data?.aplicativo?.configs?.dataCConfigPage && (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tab configs"
            >
              <Tab label="Configs" value={2} />
            </Tabs>
          )}
        </Stack>
      </ContentTitle.Root>

      {error && <Alert severity="error">{error.message}</Alert>}
      <Content>
        <TabPanel value={value} index={0}>
          <TabEditor contrato={data} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabToken contrato={data} />
        </TabPanel>

        {data?.aplicativo?.configs?.dataCConfigPage && (
          <TabPanel value={value} index={2}>
            <DynamicComponent
              dataCConfigPage={data?.aplicativo?.configs?.dataCConfigPage}
              contrato={data}
            />
          </TabPanel>
        )}
      </Content>
    </Container>
  )
}
